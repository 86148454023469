<template>
    <div>
        <div class="step">
            <el-steps :active="active" align-center>
                <el-step title="基本信息" description="设置模板基础信息" @click.native="handleStepClick(1)"></el-step>
                <el-step title="内容及样式" description="按照实际需求设置内容项" @click.native="handleStepClick(2)"></el-step>
                <!-- <el-step title="流程" description="设置作业票的签署和审批流程"></el-step> -->
                <el-step title="完成" description="完成编辑并预览测试" @click.native="handleStepClick(3)"></el-step>
            </el-steps>
        </div>
        <div v-show="active == 1 || active == 3">
            <head-layout head-title="基础信息" :head-btn-options="headBtnOptions" @head-save="headSave" @head-up="headUp"
                @head-download="headDownload" @head-start="headStart" @head-next="headNext"></head-layout>
            <el-scrollbar>
                <form-layout ref="formLayout" :column="formColumn" :dataForm="dataForm"></form-layout>
            </el-scrollbar>
        </div>
        <div v-if="active == 2" style="height: 100vh;">
            <head-layout head-title="模板内容" :head-btn-options="editorHeadBtnOptions" @head-save="headSave('save')"
                @head-up="headUp" @head-test="headMode('form')" @head-view="headMode('readonly')"
                @head-back="headMode('design')" @head-next="headNext"></head-layout>
            <Editor v-if="mode == 'design'" :mode="mode" ref="editor1" :loadHtml="templateJson"
                @onAfterInit="exportFile" />
            <Editor v-if="mode !== 'design'" :mode="mode" ref="editorView" :loadHtml="templateJsonView"
                @onAfterInit="onAfterInit('editorView')" />
        </div>
        <div v-if="active == 3">
            <el-tabs v-model="activeName">
                <el-tab-pane label="填报模式" name="form" style="height: 100vh;">
                    <Editor ref="editor2" v-if="activeName == 'form'" mode="form" :loadHtml="templateJsonView"
                        @onAfterInit="onAfterInit('editor2')" />
                </el-tab-pane>
                <el-tab-pane label="浏览模式" name="readonly" style="height: 100vh;">
                    <Editor ref="editor3" v-if="activeName == 'readonly'" mode="readonly" :loadHtml="templateJsonView"
                        @onAfterInit="onAfterInit('editor3')" />
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog title="模板启用提示" :visible.sync="dialogVisible" width="30%">
            <span>此类型作业票已经有启用的模板，是否继续启用？
                继续启用，则会将其他版本模板设置为“未启用”状态。</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">确认启用</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { dateFormat } from "@/util/date"
import ServerNameEnum from "@/util/ServerNameEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import Editor from "@/views/editor/index"
import { ticketTemplateSave, ticketTemplateDetail, processList, getVersion, enable, updateEnable, templateCode, ticketType } from "@/api/workTicket/index"
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            active: 1,
            dataForm: {},
            mode: 'design',
            activeName: 'form',
            type: '',
            dialogVisible: false,
            templateJson: '',
            templateJsonView: "",
            kictectTypeList: []
        };
    },

    components: { HeadLayout, FormLayout, Editor },
    computed: {
        ...mapGetters(['userInfo']),
        headBtnOptions() {
            if (this.active == 1) {
              if (this.type == 'view') {
                return []
              } else {
                return [{
                  label: '保存',
                  emit: "head-save"
                }, {
                  label: '下一步',
                  emit: "head-next"
                }]
              }
            } else if (this.active == 3) {
                if (this.type == 'view') {
                    return []
                } else {
                    return [{
                        label: '下载',
                        emit: "head-download"
                    }, {
                        label: '启用',
                        emit: "head-start"
                    },
                    {
                        label: '上一步',
                        emit: "head-up"
                    }]
                }

            }

        },
        editorHeadBtnOptions() {
              if (this.type == 'view') {
                return []
              } else if (this.mode == 'design') {
                return [{
                    label: '保存',
                    emit: "head-save"
                },
                {
                    label: '测试',
                    emit: "head-test"
                },
                {
                    label: '浏览',
                    emit: "head-view"
                },
                {
                    label: '上一步',
                    emit: "head-up"
                },
                {
                    label: '下一步',
                    emit: "head-next"
                }]

            } else {
                return [{
                    label: '保存',
                    emit: "head-save"
                },
                {
                    label: '测试',
                    emit: "head-test"
                },
                {
                    label: '浏览',
                    emit: "head-view"
                },
                {
                    label: '返回模板',
                    emit: "head-back"
                },
                {
                    label: '上一步',
                    emit: "head-up"
                },
                {
                    label: '下一步',
                    emit: "head-next"
                }]
            }

        },
        formColumn() {
            return [
                {
                    label: '模板编号',
                    prop: 'code',
                    labelWidth: 150,
                    span: 12,
                    maxlength: 50,
                    disabled: this.type == 'view' || this.active == 3,
                    placeholder: '请输入模板编号',
                    rules: [
                        {
                            required: true,
                            message: '请输入模板编号',
                            trigger: "blur"
                        },
                    ],
                },
                {
                    label: '模板说明',
                    disabled: this.type == 'view' || this.active == 3,
                    prop: 'name',
                    labelWidth: 150,
                    span: 12,
                    maxlength: 100,
                    placeholder: '请输入模板说明',
                    rules: [
                        {
                            required: true,
                            message: '请输入模板说明',
                            trigger: "blur"
                        },
                    ],
                },
                {
                    label: '作业票类型',
                    prop: 'ticketType',
                    disabled: this.type == 'view' || this.active == 3,
                    labelWidth: 150,
                    span: 12,
                    type: 'select',
                    dataType: "string",
                    dicUrl: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=work_permit_type`,
                    props: {
                        label: "dictValue",
                        value: "dictKey"
                    },
                    placeholder: '请选择作业票类型',
                    rules: [
                        {
                            required: true,
                            message: '请选择作业票类型',
                            trigger: "change"
                        },
                    ],
                    change: (val) => {
                        if (this.type == 'add' && val.value) {
                            this.getTemplateVersion(val.value)
                        }
                    },
                    control: (val) => {
                        if (val) {
                            return {
                                version: {
                                    display: true
                                },
                            }
                        } else {
                            return {
                                version: {
                                    display: false
                                },
                            }
                        }
                    }
                },
                {
                    label: '版本号',
                    prop: 'version',
                    labelWidth: 150,
                    span: 12,
                    placeholder: '请输入版本号',
                    display: true,
                    disabled: true,
                    rules: [
                        {
                            required: true,
                            message: '请输入版本号',
                            trigger: "blur"
                        },
                    ],
                },
                {
                    label: '创建时间',
                    type: "datetime",
                    disabled: this.type == 'view' || this.active == 3,
                    prop: 'createTime',
                    value: dateFormat(new Date()),
                    format: 'yyyy-MM-dd HH:mm:ss',
                    valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    labelWidth: 150,
                    span: 12,
                    placeholder: '请选择创建时间',
                    rules: [
                        {
                            required: true,
                            message: '请选择创建时间',
                            trigger: "blur"
                        },
                    ],
                },
                {
                    label: '关联流程',
                    prop: 'processKey',
                    labelWidth: 150,
                    disabled: this.type == 'view' || this.active == 3,
                    span: 12,
                    type: 'select',
                    dataType: "string",
                    dicData: [],
                    props: {
                        label: "name",
                        value: "key"
                    },
                    placeholder: '请选择流程',
                    rules: [
                        {
                            required: true,
                            message: '请选择流程',
                            trigger: "change"
                        },
                    ],
                },
                {
                    label: '备注',
                    type: 'textarea',
                    disabled: this.type == 'view' || this.active == 3,
                    prop: 'remark',
                    rows: 3,
                    maxlength: 500,
                    labelWidth: 150,
                    showWordLimit: true,
                    span: 24,
                    placeholder: '请输入备注',
                },
            ]
        },
    },
    created() {
        let { id, type, templateType } = this.$route.query;
        this.type = type;
        if (this.type != 'add') {
            this.initData(id)
        } else {
            this.getTemplateCode(templateType)
        }
        this.getProcessList();
        this.getTicketList();
    },
    mounted() {

    },

    methods: {
        exportFile() {
            let that = this
            let ticket = this.kictectTypeList.filter(item => item.dictKey == this.dataForm.ticketType)
            let downloadName = ticket[0].dictValue + this.dataForm.version;
            setTimeout(() => {
                that.$refs.editor1.editor.document.title = downloadName
            }, 1000)
            const iframe = this.$refs.editor1.$el.firstChild;
            let iframeDocument = iframe.contentDocument;
            if (iframeDocument) {
                var element = iframeDocument.getElementById('insert');
                element.children[14].style.display = 'none';
                element.children[15].style.display = 'none'
            }
        },
        handleStepClick(num) {
            if (this.dataForm.isEnable == 0) {
                this.active = num
            }
            if (this.type === 'edit' && num === 1) {
                this.getProcessList()
            }
        },
        onAfterInit(editor) {
            const iframe = this.$refs[editor].$el.firstChild;
            let iframeDocument = iframe.contentDocument;
            if (iframeDocument) {
                var element = iframeDocument.getElementById('_toolbar');
                if (element) element.style.display = 'none';
                var main = iframeDocument.getElementById('_main');
                if (main) main.style.top = 0; main.style.height = '100%';
            }
        },
        getTicketList() {
            ticketType('work_permit_type').then(res => {
                if (res.data.code == 200) {
                    this.kictectTypeList = res.data.data
                }
            })
        },
        getTemplateCode(templateType) {
            templateCode().then(res => {
                if (res.data.code == 200) {
                    this.$refs.formLayout.dataForm.code = res.data.data;
                    this.$refs.formLayout.dataForm.templateType = templateType;
                }
            })
        },
        //启用
        headStart() {
            enable(this.dataForm.id).then(res => {
                if (res.data.code == 200) {
                    if (res.data.data) {
                        this.dialogVisible = true
                    } else {
                        this.submit()
                    }
                }
            })
        },
        submit() {
            updateEnable(this.dataForm.id).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('模板启用成功');
                    this.$router.$avueRouter.closeTag();
                    this.$router.back();
                }
            })
        },
        getTemplateVersion(val) {
            let params = {
                templateType: this.$refs.formLayout.dataForm.templateType,
                organizationId: this.userInfo.dept_id,
                ticketType: val
            }
            getVersion(params).then(res => {
                if (res.data.code == 200) {
                    this.$refs.formLayout.dataForm.version = res.data.data;
                }
            })
        },
        initData(id) {
            ticketTemplateDetail(id).then(res => {
                if (res.data.code == 200) {
                    this.dataForm = res.data.data
                    if (this.dataForm.templateJson) {
                        this.active = 3;
                    } else {
                        this.active = 2;
                    }
                    this.templateJson = this.htmlDecode(this.dataForm.templateJson)
                    this.templateJsonView = this.htmlDecode(this.dataForm.templateJson)
                }
            })
        },
        getProcessList() {
            processList(1, 9999).then(res => {
                if (res.data.code == 200) {
                    let list = res.data.data.records.filter(
                        (item) => item.category == '作业票'
                    )
                    const column = this.findObject(this.formColumn, "processKey");
                    column.dicData = list;
                }
            })
        },
        headNext() {
            this.headSave();
        },
        headUp() {
            if (this.active-- < 1) this.active = 1;
            if (this.active == 2) this.mode = 'design'
        },
        headMode(val) {
            if (!this.templateJson) {
                this.$message.warning('请先保存模板！')
                return;
            }
            this.mode = val
        },
        headDownload() {
            let ticket = this.kictectTypeList.filter(item => item.dictKey == this.dataForm.ticketType)
            let downloadName = ticket[0].dictValue + this.dataForm.version
            if (this.activeName == 'form') {
                this.$refs.editor2.editor.execCommand('exportPdf', { 'fileName': downloadName })
            } else {
                this.$refs.editor3.editor.execCommand('exportPdf', { 'fileName': downloadName })
            }
        },
        htmlEncode(html) {
            //1.首先动态创建一个容器标签元素，如DIV
            let temp = document.createElement("div");
            //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
            (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
            //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
            let output = temp.innerHTML;
            temp = null;
            return output;
        },
        htmlDecode(text) {
            //1.首先动态创建一个容器标签元素，如DIV
            let temp = document.createElement("div");
            //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
            temp.innerHTML = text;
            //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
            let output = temp.innerText || temp.textContent;
            temp = null;
            return output;
        },
        headSave(str) {
            let that = this
            that.$refs.formLayout.$refs.form.validate((valid) => {
                if (valid) {
                    let params = {
                        ...that.$refs.formLayout.dataForm,
                    }
                    if (that.type == "add") {
                      params['organizationId'] = that.userInfo.dept_id
                      params['createUserName'] = that.userInfo.real_name
                    }
                    if (that.active == 2) {
                        if (that.$refs.editor1) {
                            params['templateJson'] = that.htmlEncode(that.$refs.editor1.editor.getHtml())
                        } else {
                            params['templateJson'] = that.htmlEncode(that.templateJson)
                        }
                    }
                    ticketTemplateSave(
                        params
                    ).then(res => {
                        const { code, msg } = res.data;
                        if (code == 200) {
                            if (!str) {
                                if (that.active++ > 2) that.active = 1;
                            }
                            if (that.active == 2) that.mode = 'design'
                            that.dataForm = res.data.data;
                            that.templateJson = that.htmlDecode(that.dataForm.templateJson)
                            that.templateJsonView = that.htmlDecode(that.dataForm.templateJson)
                            that.$refs.formLayout.$refs.form.allDisabled = false;
                            that.$message.success(that.$t('cip.cmn.msg.success.operateSuccess'))
                        } else {
                            that.$message.success(msg);
                        }
                    })
                }
            })
        },
    },
}
</script>

<style scoped lang='scss'>
.step {
    background: #fff;
    padding: 12px;
    margin-bottom: 12px;
}

::v-deep .el-tabs__header {
    padding: 0 12px;
    margin: 0;
    background: #fff;
}

::v-deep .el-step__main {
    cursor: pointer;
}
</style>
