var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "step" },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, "align-center": "" } },
            [
              _c("el-step", {
                attrs: { title: "基本信息", description: "设置模板基础信息" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleStepClick(1)
                  },
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "内容及样式",
                  description: "按照实际需求设置内容项",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleStepClick(2)
                  },
                },
              }),
              _c("el-step", {
                attrs: { title: "完成", description: "完成编辑并预览测试" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleStepClick(3)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 1 || _vm.active == 3,
              expression: "active == 1 || active == 3",
            },
          ],
        },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "基础信息",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: {
              "head-save": _vm.headSave,
              "head-up": _vm.headUp,
              "head-download": _vm.headDownload,
              "head-start": _vm.headStart,
              "head-next": _vm.headNext,
            },
          }),
          _c(
            "el-scrollbar",
            [
              _c("form-layout", {
                ref: "formLayout",
                attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.active == 2
        ? _c(
            "div",
            { staticStyle: { height: "100vh" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "模板内容",
                  "head-btn-options": _vm.editorHeadBtnOptions,
                },
                on: {
                  "head-save": function ($event) {
                    return _vm.headSave("save")
                  },
                  "head-up": _vm.headUp,
                  "head-test": function ($event) {
                    return _vm.headMode("form")
                  },
                  "head-view": function ($event) {
                    return _vm.headMode("readonly")
                  },
                  "head-back": function ($event) {
                    return _vm.headMode("design")
                  },
                  "head-next": _vm.headNext,
                },
              }),
              _vm.mode == "design"
                ? _c("Editor", {
                    ref: "editor1",
                    attrs: { mode: _vm.mode, loadHtml: _vm.templateJson },
                    on: { onAfterInit: _vm.exportFile },
                  })
                : _vm._e(),
              _vm.mode !== "design"
                ? _c("Editor", {
                    ref: "editorView",
                    attrs: { mode: _vm.mode, loadHtml: _vm.templateJsonView },
                    on: {
                      onAfterInit: function ($event) {
                        return _vm.onAfterInit("editorView")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.active == 3
        ? _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { height: "100vh" },
                      attrs: { label: "填报模式", name: "form" },
                    },
                    [
                      _vm.activeName == "form"
                        ? _c("Editor", {
                            ref: "editor2",
                            attrs: {
                              mode: "form",
                              loadHtml: _vm.templateJsonView,
                            },
                            on: {
                              onAfterInit: function ($event) {
                                return _vm.onAfterInit("editor2")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { height: "100vh" },
                      attrs: { label: "浏览模式", name: "readonly" },
                    },
                    [
                      _vm.activeName == "readonly"
                        ? _c("Editor", {
                            ref: "editor3",
                            attrs: {
                              mode: "readonly",
                              loadHtml: _vm.templateJsonView,
                            },
                            on: {
                              onAfterInit: function ($event) {
                                return _vm.onAfterInit("editor3")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "模板启用提示",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "此类型作业票已经有启用的模板，是否继续启用？\n            继续启用，则会将其他版本模板设置为“未启用”状态。"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认启用")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }